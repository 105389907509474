import { Action, ActionReducer, INIT, UPDATE } from '@ngrx/store';
import * as HydrationActions from './hydration.actions';
import { AppState } from '@core/store/core.state';
import { LocalStorageService } from '@core/services/local-storage.service';

function isHydrateSuccess(
  action: Action
): action is ReturnType<typeof HydrationActions.hydrateSuccess> {
  return action.type === HydrationActions.hydrateSuccess.type;
}

export const hydrationMetaReducer = (
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> => {
  return (state, action) => {
    if (isHydrateSuccess(action)) {
      return { ...state, ...action };
    } else {
      return reducer(state, action);
    }
  };
};
