import { Action, createReducer, on } from '@ngrx/store';
import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { SettingsState, NIGHT_MODE_THEME } from './';
import { LocalStorageService } from '@core/services';
import { inject } from '@angular/core';

const localStorage = new LocalStorageService();

import {
  actionSettingsChangeAnimationsElements,
  actionSettingsChangeAnimationsPage,
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeAutoNightMode,
  actionSettingsChangeHour,
  actionSettingsChangeLanguage,
  actionSettingsChangeStickyHeader,
  actionSettingsChangeTheme,
  actionSettingsGPRD,
} from './settings.actions';


export const initialState: SettingsState = {
  language: 'pt-br',
  theme: 'home',
  autoNightMode: false,
  nightTheme: NIGHT_MODE_THEME,
  stickyHeader: true,
  pageAnimations: true,
  pageAnimationsDisabled: false,
  elementsAnimations: false,
  hour: 0,
  gprd: null
};

const reducer = createReducer(
  initialState,
  on(
    actionSettingsChangeLanguage,
    actionSettingsChangeTheme,
    actionSettingsChangeAutoNightMode,
    actionSettingsChangeStickyHeader,
    actionSettingsChangeAnimationsPage,
    actionSettingsChangeAnimationsElements,
    actionSettingsChangeHour,
    actionSettingsGPRD,
    (state, action) => ({ ...state, ...action })
  ),
  on(
    actionSettingsChangeAnimationsPageDisabled,
    (state, { pageAnimationsDisabled }) => ({
      ...state,
      pageAnimationsDisabled,
      pageAnimations: false
    }),
  ),
  // on(actionInitStateFromLocalStorage,
  //   (state, action) => ({ ...state, ...action })
  // )
);

export function settingsReducer(
  state: SettingsState | undefined,
  action: Action
) {
  return reducer(state, action);
}

