
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IConfig, NgxMaskModule } from 'ngx-mask'

const maskConfig: Partial<IConfig> = {
  validation: false,
};

const MODULES = [
  NgxMaskModule.forRoot(maskConfig),
]

@NgModule({
  imports: [CommonModule, ...MODULES],
  declarations: [],
  exports: [MODULES],
  providers: [],

})
export class ThemeModule {

  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
    };
  }
}
