import { environment } from '../..//environments/environment';

import {
  ActionReducerMap,
  MetaReducer,
  createFeatureSelector
} from '@ngrx/store';


import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { initStateFromLocalStorage } from '@core/store/local-storage/init-state-from-local-storage.reducer';
import { debug } from '@core/store/debug/debug.reducer';





//REDUCERS
import { settingsReducer } from '@core/store/settings/settings.reducer';
import { authReducer } from '@auth/store/auth.reducer';
import { errorReducer } from '@core/store/error/error.reducer';
import { userReducer } from '@core/store/user';
//STATES
import { RouterStateUrl } from '@core/store/router/router.state';
import { ErrorState } from '@core/store/error/error.state';
import { SettingsState } from '@core/store/settings';
import { AuthState } from '@auth/store/auth.models'
import { UserState } from '@core/store/user';

export const reducers: ActionReducerMap<AppState> = {
  settings: settingsReducer,
  auth: authReducer,
  user: userReducer,
  router: routerReducer,
  error: errorReducer

};

// export const metaReducers: MetaReducer<AppState>[] = [
//   initStateFromLocalStorage
// ];

import { hydrationMetaReducer } from '@core/store/hydration/hydration.reducer';

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];

if (!environment.production) {
  if (environment.test) {
    metaReducers.unshift(debug);
  }
}



export interface AppState {
  settings: SettingsState;
  router: RouterReducerState<RouterStateUrl>;
  error: ErrorState;
  auth: AuthState;
  user: UserState

}
