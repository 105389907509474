import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { PLATFORM_ID, afterNextRender, afterRender, AfterRenderPhase } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { switchMap } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment as env } from '~app/src/environments/environment';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {


  private retry: boolean = false;

  constructor(private injector: Injector,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (!env.production) {
      console.log(' DEFAULT INTERCEPTIOR')
    }

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //apply this only on api/*
    if (!(new RegExp('api/v2/frontend').test(req.url))) {

      if (isPlatformBrowser(this.platformId)) {
        const ignoredURL = new RegExp('api/v2/frontend').test(req.url);

        // const isAbsoluteURL = new RegExp('api').test(req.url)
        if (!ignoredURL) {
          const authState = localStorage && localStorage.getItem('ECD-AUTH') ? JSON.parse(localStorage.getItem('ECD-AUTH')!) : null;
          const authToken = authState ? authState.access_token : null;

          if (authToken) {
            // Clone the request and add the authorization header
            const authReq = req.clone({
              setHeaders: {
                Authorization: `Bearer ${authToken}`
              }
            });
            // Pass the cloned request with the updated header to the next handler

            return next.handle(authReq).pipe(
              map((event: HttpEvent<any>) => {
                return event;
              }),
              catchError((error) => {
                let handled: boolean = false;
                if (error instanceof HttpErrorResponse) {
                  if (error.error instanceof ErrorEvent) {
                    console.error("Error Event");
                  } else {
                    switch (error.status) {
                      case 0:
                        handled = true;
                        break;
                      case 401:
                        //SKIP HANDLE WHEN TRY TO ROUTE LOGIN
                        if (new RegExp('auth/login').test(req.url)) {
                          handled = false;
                        } else {
                          //WHEN NO AUTH OR TOKEN EXPIRED
                          this.router.navigate(['auth/login'])
                          handled = true;
                        }
                        break;
                      case 500:
                        handled = true;
                        break;
                    }
                  }
                }
                if (handled) {
                  return of(error);
                } else {
                  return throwError(() => error);
                }
              })
            );
          }

        }

      }
      return next.handle(req);

    } else {
      return next.handle(req);
    }
  }
}