import { ActivationEnd, Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, of } from 'rxjs';
import {
  tap,
  withLatestFrom,
  distinctUntilChanged,

  filter
} from 'rxjs/operators';

import { ThemeService, MenuService } from '@core/services';

import { selectSettingsState } from '@core/store/settings/settings.selectors';
import { LocalStorageService } from '@core/services/local-storage.service';
// import { AnimationsService } from '../animations/animations.service';
import { TitleService } from '@core/services/title.service';
import { AppState } from '../core.state';

import {
  actionSettingsChangeAnimationsElements,
  actionSettingsChangeAnimationsPage,
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeAutoNightMode,
  actionSettingsChangeLanguage,
  actionSettingsChangeTheme,
  actionSettingsChangeStickyHeader,
  actionSettingsChangeHour,
  actionSettingsGPRD,
  actionSettingsINIT
} from './settings.actions';
import {
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectPageAnimations,
  selectElementsAnimations,
  selectGPRD
} from './settings.selectors';
import { SettingsState } from './';

export const SETTINGS_KEY = 'SETTINGS';

const INIT = of('ecd-init-effect-trigger');

@Injectable()
export class SettingsEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private localStorageService: LocalStorageService,
    private titleService: TitleService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private themeService: ThemeService,
    private menuService: MenuService
  ) { }

  hour = 0;

  changeHour = this.ngZone.runOutsideAngular(() =>
    setInterval(() => {
      const hour = new Date().getHours();
      if (hour !== this.hour) {
        this.hour = hour;
        this.ngZone.run(() =>
          this.store.dispatch(actionSettingsChangeHour({ hour }))
        );
      }
    }, 60_000)
  );

  persistSettings = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          actionSettingsChangeAnimationsElements,
          actionSettingsChangeAnimationsPage,
          actionSettingsChangeAnimationsPageDisabled,
          actionSettingsChangeAutoNightMode,
          actionSettingsChangeLanguage,
          actionSettingsChangeStickyHeader,
          actionSettingsChangeTheme,
          actionSettingsGPRD
        ),
        withLatestFrom(this.store.pipe(select(selectSettingsState))),
        tap(([action, settings]) =>
        // this.localStorageService.isBrowser() && this.localStorageService.setItem(SETTINGS_KEY, settings)
        {
          const init = this.localStorageService.setItem(SETTINGS_KEY, settings)
        }

        )
      ),
    { dispatch: false }
  );

  // updateRouteAnimationType = createEffect(
  //   () =>
  //     merge(
  //       INIT,
  //       this.actions$.pipe(
  //         ofType(
  //           actionSettingsChangeAnimationsElements,
  //           actionSettingsChangeAnimationsPage
  //         )
  //       )
  //     ).pipe(
  //       withLatestFrom(
  //         combineLatest([
  //           this.store.pipe(select(selectPageAnimations)),
  //           this.store.pipe(select(selectElementsAnimations))
  //         ])
  //       ),
  //       tap(([action, [pageAnimations, elementsAnimations]]) =>
  //         this.animationsService.updateRouteAnimationType(
  //           pageAnimations,
  //           elementsAnimations
  //         )
  //       )
  //     ),
  //   { dispatch: false }
  // );

  // updateTheme = createEffect(
  //   () =>
  //     merge(INIT, this.actions$.pipe(ofType(actionSettingsChangeTheme))).pipe(
  //       withLatestFrom(this.store.pipe(select(selectEffectiveTheme))),
  //       tap(([action, effectiveTheme]) => {
  //         const classList = this.overlayContainer.getContainerElement()
  //           .classList;
  //         const toRemove = Array.from(classList).filter((item: string) =>
  //           item.includes('-theme')
  //         );
  //         if (toRemove.length) {
  //           classList.remove(...toRemove);
  //         }
  //         classList.add(effectiveTheme);
  //       })
  //     ),
  //   { dispatch: false }
  // );

  updateTheme = createEffect(
    () =>
      merge(INIT, this.actions$.pipe(ofType(actionSettingsChangeTheme))).pipe(
        withLatestFrom(this.store.pipe(select(selectEffectiveTheme))),
        tap(([action, effectiveTheme]) => {
          this.themeService.theme = effectiveTheme
        })
      ),
    { dispatch: false }
  );


  setTranslateServiceLanguage = createEffect(
    () =>
      this.store.pipe(
        select(selectSettingsLanguage),
        distinctUntilChanged(),
        tap((language) => {
          this.translateService.use(language);
          this.menuService.translateMenus();

        })
      ),
    { dispatch: false }
  );

  setTitle = createEffect(
    () =>
      merge(
        this.actions$.pipe(ofType(actionSettingsChangeLanguage)),
        this.router.events.pipe(
          filter((event) => event instanceof ActivationEnd)
        )
      ).pipe(
        tap(() => {
          this.titleService.setTitle(
            this.router.routerState.snapshot.root,
            this.translateService
          );
        })
      ),
    { dispatch: false }
  );


}
