import { Injectable, Inject, PLATFORM_ID, afterNextRender, afterRender, AfterRenderPhase } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
//3TH
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { select, Store, Action } from '@ngrx/store';
import { catchError, of, tap, exhaustMap } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

//MODELS
import { User } from '@core/models'
//STORE
import * as userActions from '@core/store/user/user.action'
import * as userSelectors from '@core/store/user/user.selectors'
import { AppState } from '@core/store/core.state';
import { UserService } from '@core/store/user'
import { AuthService } from '@auth/store'
import * as errorActions from '@core/store/error'

@Injectable({ providedIn: 'root' })

export class UserEffects {
    constructor(private httpClient: HttpClient,
        private actions$: Actions,
        private store: Store,
        private userService: UserService,
        private authService: AuthService,
        @Inject(PLATFORM_ID) private platformId: any
    ) { }


    getUserEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.getUser),
            exhaustMap(() =>
                this.userService.me().pipe(
                    map(res => userActions.setUser({ ...res.data })),
                    catchError(error => of(errorActions.createError({ action: userActions.getUser.type, errors: error })))

                )
            ),
        )
    );
    ;


    // userChechAuthEffect$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(userActions.checkAuth),
    //         exhaustMap(() => this.authService.isAuthenticated()
    //         ),
    //         tap((isAuth: boolean) => {
    //             if (isAuth) {
    //                 this.authService
    //                     .onTokenChange()
    //                     .subscribe((token: NbAuthToken) => {
    //                         if (token instanceof NbAuthJWTToken && token.isValid()) {
    //                             this.store.dispatch(userActions.setUser({ user: token.getPayload().user }));
    //                         }
    //                     })
    //             }
    //         }
    //         )),
    //     { dispatch: false }
    // );

    // ngrxOnInitEffects(): Action {

    //     if (isPlatformBrowser(this.platformId)) {

    //     }

    //   }


}