import { Action, createReducer, on } from '@ngrx/store';
import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import * as actionsError from './error.actions'
import { ErrorState } from './';

const initialState: ErrorState = {
  action: null,
  errors: null

}


const reducer = createReducer(
  initialState,
  on(
    actionsError.createError,
    (state, action) => ({ ...state, ...action })
  ),
  on(
    actionsError.deleteError,
    (state, action) => ({
      ...state,
      action: state.action == action.type ? null : state.action,
      errors: state.action == action.type ? null : state.errors
    })
  ),
  on(
    actionsError.clearErrors,
    (action) => ({ ...{ action: null, errors: null } })
  )
);

export function errorReducer(
  state: ErrorState | undefined,
  action: Action
) {
  return reducer(state, action);
}

