import { ApplicationConfig, importProvidersFrom, LOCALE_ID, DEFAULT_CURRENCY_CODE, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding, ExtraOptions, withRouterConfig, withInMemoryScrolling, InMemoryScrollingOptions, InMemoryScrollingFeature } from '@angular/router';
import { provideHttpClient, withInterceptors, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { provideClientHydration } from '@angular/platform-browser';
import { TransferState } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
//3TH
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './../translate-browser.loader';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { TranslateService } from '@ngx-translate/core';
import { SvgIconComponent, provideAngularSvgIcon } from 'angular-svg-icon';
//MODULES
import { ThemeModule } from './../@theme/theme.module';
//ROUTE
import { environment as env } from '../environments/environment';
import { routes } from './app.routes';
//SERVICES
import {
  ApiService,
  ApiData,
  ScrollToTopService,
  TitleService,
  ThemeService,
  LayoutService,
  MenuService,
  UtilsService,
  AlertService,
  ExcelService,
  SeoService

} from '@core/services';
//INTERCEPTIOS
import { DefaultInterceptor } from '@core/interceptors/default.interceptor';
import { authInterceptor } from '@core/interceptors/auth.interceptior';
//CONSTANTS
export const gooogleAnalyticsTrackRoutes = {
  include: [
    '/books',
  ]
}
//NGRX
import { provideStore, provideState } from '@ngrx/store'
import { provideEffects } from '@ngrx/effects'
import { provideStoreDevtools } from '@ngrx/store-devtools';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer,
  provideRouterStore
} from '@ngrx/router-store';
//STORE
import { CustomSerializer } from '@core/store/router/custom-serializer';
import { reducers, metaReducers } from '@core/store/core.state';
import { SettingsEffects } from '@core/store/settings/settings.effects';
import { AuthEffects } from '@auth/store/auth.effects';
import { HydrationEffects } from '@core/store/hydration/hydration.effects';
import { UserEffects } from '@core/store/user/user.effects';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';


// export const DEPS_HTTP = [
//   SettingsEffects, AuthEffects, HydrationEffects,
//   TranslateService, TitleService
// ]

//CONFIGS

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled'

};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);




registerLocaleData(localePt)
//

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    // provideRouter(routes, withRouterConfig(extraOptions), withComponentInputBinding()),
    provideRouter(routes, inMemoryScrollingFeature, withComponentInputBinding()),
    provideAngularSvgIcon(),
    // provideClientHydration(),
    { provide: ApiData, useClass: ApiService },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: ScrollToTopService },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    // { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true, deps: [TranslateService, TitleService] },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    //PRODUC,TION ONLY

    importProvidersFrom(BrowserModule,
      //  NgxGoogleAnalyticsModule.forRoot(env.google.analyticsID),
      //   NgxGoogleAnalyticsRouterModule.forRoot(gooogleAnalyticsTrackRoutes),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translateBrowserLoaderFactory,
          deps: [HttpClient, TransferState],
        }
      }),
      StoreRouterConnectingModule.forRoot(),
      ThemeModule.forRoot()),

    provideStore(reducers, { metaReducers }),
    provideEffects([SettingsEffects, HydrationEffects, AuthEffects, UserEffects]),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
      features: {
        pause: false,
        lock: true,
        persist: true
      }
    }),

    // provideAnimations(),
    provideAnimationsAsync('noop')
  ]
};
