import { createAction, props } from "@ngrx/store";
import { AppState } from '@core/store/core.state';

export const hydrate = createAction("[Hydration] INIT");
export const hydrateServer = createAction("[Hydration] Hydrate waiting browser..");


export const hydrateSuccess = createAction(
  "[Hydration] SUCCESS",
  props<AppState>()
);

export const hydrateFailure = createAction("[Hydration] Hydrate Failure");