import { Inject, PLATFORM_ID, afterNextRender, afterRender, AfterRenderPhase } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType, OnInitEffects } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { AppState } from '@core/store/core.state';
import * as HydrationActions from "./hydration.actions";
import { LocalStorageService } from "@core/services";

import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
const APP_PREFIX = 'ECD-';


@Injectable()
export class HydrationEffects implements OnInitEffects {

  static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) =>
                index === 0
                  ? token
                  : token.charAt(0).toUpperCase() + token.slice(1)
              )
              .join('')
          );
        let currentStateRef: any | null = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {

            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey) || "");
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  hydrate$ = createEffect(() =>
    this.action$.pipe(
      ofType(HydrationActions.hydrate),
      map(() => {
        const storageValue = HydrationEffects.loadInitialState();
        if (storageValue) {
          try {
            return HydrationActions.hydrateSuccess({ ...storageValue });

          } catch (e) {
            // localStorage.removeItem("state");
            console.log(e)
          }
        }
        return HydrationActions.hydrateFailure();
      })
    )
  );

  // serialize$ = createEffect(
  //   () =>
  //     this.action$.pipe(
  //       ofType(HydrationActions.hydrateSuccess, HydrationActions.hydrateFailure),
  //       switchMap(() => this.store),
  //       distinctUntilChanged(),
  //       tap((state) => localStorage.setItem("state", JSON.stringify(state)))
  //     ),
  //   { dispatch: false }
  // );

  constructor(private action$: Actions,
    private store: Store<AppState>,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  ngrxOnInitEffects(): Action {

    if (isPlatformBrowser(this.platformId)) {
      return HydrationActions.hydrate();
    }
    return HydrationActions.hydrateServer();
    // return HydrationActions.hydrate();
  }
}