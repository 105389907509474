import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ErrorState } from './';

export const selectErrorState = createFeatureSelector<ErrorState>('error');

// import { selectSettingsState } from '@core/core.state';


export const selectErrors = createSelector(
  selectErrorState,
  (state: ErrorState) => state.errors
);

export const selectErrorAction = createSelector(
  selectErrorState,
  (state: ErrorState) => state.action
);
