import { Routes } from '@angular/router';
import { redirectsRoutes } from './app.routes.redirects';

export const routes: Routes = [

    {
        path: '',
        loadComponent: () => import('./app.component').then((c) => c.AppComponent),
        children: [
            {
                path: '',
                loadChildren: () => import('@apps/apps.routes').then((m) => m.APPS_ROUTES),
            },
            // {
            //     path: 'auth',

            //     loadChildren: () => import('@auth/auth.routes').then((r) => r.AUTH_ROUTES)
            // },

            ...redirectsRoutes
        ]
    },
    {
        path: '404',
        children:
            [
                {
                    path: ':url',
                    loadComponent: () => import('../@theme/components/not-found/not-found.component').then((c) => c.NotFoundComponent)
                },
                {
                    path: '',
                    loadComponent: () => import('../@theme/components/not-found/not-found.component').then((c) => c.NotFoundComponent)
                }
            ]
    },

    { path: '**', redirectTo: '/404' },


];

