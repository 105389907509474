import { Routes } from '@angular/router';


export const redirectsRoutes: Routes = [

    /** REDIRECTS DA VERSÃO ANTERIOR */
    {
        path: 'livros',
        redirectTo: '/books',
        pathMatch: 'full'

    },

    {
        path: 'livros/:slug',
        redirectTo: '/books/:slug',
        pathMatch: 'full'

    },
    {
        path: 'livros/:slug',
        redirectTo: '/books/:slug',
        pathMatch: 'full'

    },

    {
        path: 'artigos/:slug',
        redirectTo: '/books/chapter/:slug',
        pathMatch: 'full'
    },
    {
        path: 'artigos/code/:slug',
        redirectTo: '/books/chapter/:slug',
        pathMatch: 'full'

    },
    {
        path: 'articles/:slug',
        redirectTo: '/books/chapter/:slug',
        pathMatch: 'full'
    },
    {
        path: 'articles/code/:slug',
        redirectTo: '/books/chapter/:slug',
        pathMatch: 'full'

    },


    {
        path: 'chamadas',
        redirectTo: '/books/chamadas',
        pathMatch: 'full'

    },
    {
        path: 'chamadas/:slug',
        redirectTo: '/books/chamadas/:slug',
        pathMatch: 'full'

    },
    {
        path: 'meu-primeiro-livro',
        redirectTo: '/promocao/meu-primeiro-livro-digital',
        pathMatch: 'full',
    },
    {
        path: 'autores/normas',
        redirectTo: '/books/autores/normas',
        pathMatch: 'full'
    }

];

