// import { AppState } from '@core/store/store.model';
import { AppState } from '@core/store/core.state';
import { Language } from '@core/models'
export const NIGHT_MODE_THEME = 'BLACK-THEME';

//  export type Language = 'en' | 'sk' | 'de' | 'fr' | 'es' | 'pt-br' | 'he';


export interface SettingsState {
  language: Language;
  theme: string;
  autoNightMode: boolean;
  nightTheme: string;
  stickyHeader: boolean;
  pageAnimations: boolean;
  pageAnimationsDisabled: boolean;
  elementsAnimations: boolean;
  hour: number;
  gprd: string | null;
}

